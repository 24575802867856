import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


function StartMonitoring(props) {

    const [heure, setHour] = useState();
    const [actif, setActif] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [ta, setTA] = useState({
        heureMsec: 0,
        syst: 120,
        dias: 60,
        fc: 70,
        satu: 99,
        co2: 35,
        papSys: 0,
        papDias: 0,
        drug: "",
        comment: ""
    })

    const [taCard, setTACard] = useState({
        heureMsec: 0,
        syst: 120,
        dias: 60,
        fc: 70,
        satu: 99,
        co2: 35,
        papSys: 0,
        papDias: 0,
        drug: "",
        comment: "PAP(mmHg): 25/12, SvO2: 70%, Total diuresis: 400ml, BIS: 40, InvosL/R: 60/60, Temp: 36,0°"
    })

    function setHourHandle() {
        const n = new Date();
        const nMsec = n.getTime();
        setTA(prevValue => {
            return {
                ...prevValue,
                heureMsec: nMsec
            }
        })
        setTACard(prevValue => {
            return {
                ...prevValue,
                heureMsec: nMsec
            }
        })

        setHour(n.toLocaleString("fr-BE", { timeStyle: "short" }));

        setIsClicked(true)
    }



    function lessFive(x) {
        const dateAleatoire = new Date();
        const hMsec = ta.heureMsec;
        const hMsecLessFive = hMsec - x * 60000;
        setTA(prevValue => {
            return {
                ...prevValue,
                heureMsec: hMsecLessFive
            }
        })
        setTACard(prevValue => {
            return {
                ...prevValue,
                heureMsec: hMsecLessFive
            }
        })
        dateAleatoire.setTime(hMsecLessFive);

        setHour(dateAleatoire.toLocaleString("fr-BE", { timeStyle: "short" }));

    }

    function sendParam() {
        if (props.cardiacParamIsShow) {
            props.onAddParam(taCard)

        } else {

            props.onAddParam(ta);
        }
        setIsClicked(false);
        setActif(true);
    }

    return (
        <React.Fragment>
            <ButtonGroup sx={{paddingRight:"5px"}} size="small" variant="outlined"  >
                <Button disabled={actif} style={{ backgroundColor: "#00C387", color: "white" }} startIcon={<AirlineSeatFlatIcon />} variant="contained" color="primary" size="small" onClick={setHourHandle}>{props.label} {heure}</Button>
                {isClicked &&
                    <ButtonGroup >
                        <Button sx={{ borderColor: 'black', color: 'black' }} variant="outlined" startIcon={<AccessTimeIcon />} onClick={() => lessFive(1)} >-1min</Button>
                        <Button sx={{ borderColor: 'black', color: 'black' }} variant="outlined" onClick={() => lessFive(5)} >-5min</Button>
                        <Button sx={{ borderColor: 'black', color: 'black' }} onClick={sendParam} >OK</Button>
                    </ButtonGroup>
                }
            </ButtonGroup>



        </React.Fragment>
    )
};

export default StartMonitoring;

