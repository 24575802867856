import React from 'react';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';



function GeneralList(props) {
    
    const [drug, setDrug] = useState(props.drug);

    const stringIndex = props.index.toString();

    function handleChange(event) {
        const value = event.target.value;
        const newDrug = drug;

        newDrug.dose = value

        setDrug(prevValue => {
            return {
                ...prevValue, dose: value
            }
        })
        props.send(newDrug, props.index)

    };

    function handleCheck(event) {
        const check = event.target.checked;

        const newDrug = drug;

        newDrug.selected = check;
        
        setDrug(prevValue => {
            return {
                ...prevValue, selected: check
            }
        });
        props.send(newDrug, props.index)

    }

    return (
        <Paper elevation={0}>
            <FormControlLabel control={<Checkbox checked={drug.selected} name={drug.name} onChange={handleCheck} />} label={drug.name} />
            {drug.selected &&
                 
                    <TextField variant="standard" style={{ width: 700 }} value={drug.dose} name={stringIndex} onChange={handleChange} />
                
            }
        </Paper>)

}

export default GeneralList;