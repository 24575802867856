import React, { useState } from "react";
import { Typography, Grid, Paper, Divider, Checkbox, IconButton, FormControlLabel } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { oneDayData } from './TypeOfAnesthesia/DataProtocol';
import PopOneDay from './TypeOfAnesthesia/PopUpInduction/PopOneDay';


function OneDay() {

    const [oneday, setOneday] = useState(oneDayData)
    const [isChecked, setIsChecked] = useState(true)
    const [editOneDay, setEditOneDay] = useState(false)

    function handleChange() {
        setIsChecked(!isChecked)
    }

    function handleOneDay() {
        setEditOneDay(true)
    }

    function addOneDay(chosenPonction) {
        setOneday(chosenPonction)
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Paper sx={{ marginTop: 10 }}>
                        <FormControlLabel
                            control={<Checkbox style={{ color: "#039BFF" }} checked={isChecked} onChange={handleChange} />}
                            label={<Typography variant="h6">OneDay</Typography>}
                        />
                        {isChecked &&
                            <IconButton style={{ color: "#039BFF" }} onClick={handleOneDay} size="small" >
                                <EditIcon />
                            </IconButton>
                        }

                        {editOneDay &&
                            <PopOneDay
                                rachiPonction={oneday}
                                titre="One Day"
                                chosenPonction={addOneDay}
                                close={() => setEditOneDay(false)}
                            />
                        }

                        <Divider />

                        {isChecked &&
                            oneday.map((item, index) => {
                                return item.selected && (
                                    <Typography key={index} variant="body1" >{item.name} {item.dose} </Typography>
                                )
                            })}
                    </Paper>
                </Grid>

                <Grid item xs={6}>

                    {isChecked &&
                        <Paper sx={{ marginTop: 10 }}>
                            <Typography variant="h6" sx={{ marginBottom: "5px", paddingTop: "5px" }}>Critères de sortie (score minimun = 9 points)</Typography>
                            <Divider />
                            <Typography variant="body2">Oxygenation: SpO2 {">"} 92% &emsp; 2 points<br /> &emsp; &emsp; &emsp; &emsp;&emsp; SpO2 {">"} 90% avec de l'oxygène &emsp; 1 point<br />&emsp; &emsp; &emsp; &emsp;&emsp; SpO2 {"<"} 90% avec de l'oxygène &emsp; 0 point</Typography>
                            <br />
                            <Typography variant="body2">Respiration: Grands mouvements respiratoires &emsp; 2 points<br /> &emsp;&emsp; &emsp; &emsp;&emsp; Efforts respiratoires limités ou dyspnée &emsp; 1 point<br />&emsp;&emsp; &emsp; &emsp;&emsp; Apnée ou obstruction &emsp; 0 point</Typography>
                            <br />
                            <Typography variant="body2">Ecart de PA systolique préopératoire: +-20 mmHg &emsp; 2 points<br /> &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp;&emsp;+- 20 à 50 mmHg &emsp; 1 point<br /> &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;&emsp;&emsp; plus de +- 50 mmHg &emsp; 0 point</Typography>
                            <br />
                            <Typography variant="body2">Conscience: Bien réveillé &emsp; 2 points<br /> &emsp; &emsp; &emsp;&emsp;&emsp; Réveillé à l'appel &emsp; 1 point<br />&emsp; &emsp; &emsp;&emsp;&emsp; Aucune réponse &emsp; 0 point</Typography>
                            <br />
                            <Typography variant="body2">Activité: Bouge les 4 membres &emsp; 2 points<br /> &emsp; &emsp; &emsp;&nbsp;Bouge 2 membres &emsp; 1 point<br /> &emsp; &emsp; &emsp;&nbsp;Immobile &emsp; 0 point</Typography>

                        </Paper>
                    }
                </Grid>

            </Grid>
        </div>
    )
}

export default OneDay;