const drugs = [
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Propofol(mg): ", dose: "200", selected: true },
    { name: "Propofol TCI(µg/cc): ", dose: "5->3", selected: false },
    { name: "Sufentanil(µg): ", dose: "5", selected: true },
    { name: "Remifentanil(µg/kg/min): ", dose: "0,5->0,125", selected: false },
    { name: "Remifentanil TCI(µg/cc): ", dose: "3", selected: false },
    { name: "Ketamin(mg): ", dose: "25", selected: true },
    { name: "Ketamin(mg/h): ", dose: "15", selected: false },
    { name: "Lidocain(mg): ", dose: "60", selected: true },
    { name: "Dexamethason(mg): ", dose: "10", selected: true },
    { name: "Cefazolin(g): ", dose: "2", selected: true },
    { name: "Augmentin(g): ", dose: "2", selected: false },
    { name: "Cefuroxim(g): ", dose: "1,5", selected: false },
    { name: "Dalacin(mg): ", dose: "600", selected: false },
    { name: "Rocuronium(mg): ", dose: "35", selected: true },
    { name: "Rocuronium(mg/h): ", dose: "20", selected: false },
    { name: "Cisatracurium(mg): ", dose: "16", selected: false },
    { name: "Cisatracurium(mg/h): ", dose: "5", selected: false },
    { name: "Succinylcholine(mg): ", dose: "100", selected: false },
    { name: "Atropine(mg): ", dose: "0,5", selected: false },
    { name: "Ephedrine(mg): ", dose: "6", selected: false },
    { name: "Phenylephrine(µg): ", dose: "100", selected: false },
    { name: "Sevoflurane(%ET): ", dose: "2,0", selected: true },
    { name: "Clonidine(µg): ", dose: "150", selected: false },
    { name: "Exacyl(g): ", dose: "1,5", selected: false },
    { name: "MgSO4(g): ", dose: "2", selected: false },
    { name: "Flagyl(g): ", dose: "1,5", selected: false },
    { name: "Amikacine(g): ", dose: "1", selected: false },
]

const pediaDrugs = [
    { name: "Inhalation sevoflurane(%ET): ", dose: "7->3,0", selected: true },
    { name: "Propofol(mg): ", dose: "20", selected: true },
    { name: "Propofol(µg/cc): ", dose: "5->3", selected: false },
    { name: "Sufentanil(µg): ", dose: "2", selected: true },
    { name: "Remifentanil(µg/kg/min): ", dose: "0,5->0,125", selected: false },
    { name: "Ketamin(mg): ", dose: "25", selected: false },
    { name: "Lidocain(mg): ", dose: "60", selected: false },
    { name: "Dexamethason(mg): ", dose: "3", selected: true },
    { name: "Cefazolin(g): ", dose: "2", selected: false },
    { name: "Augmentin(g): ", dose: "2", selected: false },
    { name: "Rocuronium(mg): ", dose: "10", selected: false },
    { name: "Cisatracurium(mg): ", dose: "4", selected: false },
    { name: "Succinylcholine(mg): ", dose: "50", selected: false },
    { name: "Clonidine(µg): ", dose: "25", selected: false },
    { name: "MgSO4(g): ", dose: "2", selected: false },
    { name: "Flagyl(g): ", dose: "1,5", selected: false },
    { name: "Amikacine(g): ", dose: "1", selected: false },
]

const laryngealDrugs = [
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Propofol(mg): ", dose: "200", selected: true },
    { name: "Propofol(µg/cc): ", dose: "5->3", selected: false },
    { name: "Sufentanil(µg): ", dose: "5", selected: true },
    { name: "Remifentanil(µg/kg/min): ", dose: "0,5->0,125", selected: false },
    { name: "Remifentanil TCI(µg/cc): ", dose: "3", selected: false },
    { name: "Ketamin(mg): ", dose: "25", selected: false },
    { name: "Lidocain(mg): ", dose: "100", selected: true },
    { name: "Dexamethason(mg): ", dose: "10", selected: true },
    { name: "Cefazolin(g): ", dose: "2", selected: true },
    { name: "Augmentin(g): ", dose: "2", selected: false },
    { name: "Rocuronium(mg): ", dose: "35", selected: false },
    { name: "Cisatracurium(mg): ", dose: "16", selected: false },
    { name: "Succinylcholine(mg): ", dose: "100", selected: false },
    { name: "Clonidine(µg): ", dose: "150", selected: false },
    { name: "Sevoflurane(%ET): ", dose: "2,0", selected: true },
    { name: "MgSO4(g): ", dose: "2", selected: false },
    { name: "Flagyl(g): ", dose: "1,5", selected: false },
    { name: "Amikacine(g): ", dose: "1", selected: false },
]

const sedationDrugs = [
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Sufentanil(µg): ", dose: "5", selected: true },
    { name: "Ketamin(mg): ", dose: "25", selected: true },
    { name: "Propofol(mg): ", dose: "20", selected: true },
    { name: "Propofol(mg/h): ", dose: "200", selected: false },
    { name: "Propofol(µg/cc): ", dose: "1,0", selected: false },
    { name: "Remifentanil(µg/kg/min): ", dose: "0,0625", selected: false },
    { name: "Remifentanil TCI(µg/ml): ", dose: "3", selected: false },
    { name: "Cefazolin(g): ", dose: "2", selected: false },
    { name: "Augmentin(g): ", dose: "2", selected: false },
]

const sedationDDSDrugs = [
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Sufentanil(µg): ", dose: "5", selected: true },
    { name: "Lidocain(mg): ", dose: "60", selected: false },
    { name: "Ketamin(mg): ", dose: "25", selected: false },
    { name: "Propofol(mg): ", dose: "20", selected: false },
    { name: "Propofol(µg/cc): ", dose: "1,0", selected: true },
    { name: "Remifentanil(µg/kg/min): ", dose: "0,0625", selected: true },
    { name: "Remifentanil TCI(µg/ml): ", dose: "3", selected: false },
    { name: "Dexamethason(mg): ", dose: "10", selected: false },
    { name: "Solumedrol(mg): ", dose: "40", selected: true },
    { name: "Cefazolin(g): ", dose: "2", selected: false },
    { name: "Augmentin(g): ", dose: "2", selected: false },
]

const endoDrugs = [
    { name: "Midazolam(mg): ", dose: "2", selected: true },
    { name: "Ketamin(mg): ", dose: "25", selected: true },
    { name: "Lidocain(mg): ", dose: "100", selected: false },
    { name: "Propofol(mg): ", dose: "20 mg titrated on demand", selected: false },
    { name: "Propofol(cc/h): ", dose: "40", selected: true },
]


const monitoring = [
    { name: "ECG (Sinus rhythm)", selected: true },
    { name: "ECG (Atrial fibrillation)", selected: false },
    { name: "SpO2", selected: true },
    { name: "NIBP", selected: true },
    { name: "Gastric tube", selected: false },
    { name: "Thermic sonde", selected: false },
    { name: "NMT", selected: false },
    { name: "BIS", selected: false },
    { name: "INVOS", selected: false },
    { name: "TEEchocardiography", selected: false },
]

const fluids = [
    { name: "SteroIso 1L", selected: false },
    { name: "SteroIso 500ml", selected: false },
    { name: "Plasmalyte 1L", selected: false },
    { name: "Plasmalyte 500ml", selected: false },
    { name: "Plasmalyte 500ml + metrisette", selected: false },
    { name: "Plasmalyte 500ml + dialaflow", selected: false },
    { name: "NaCl 1L", selected: false },
    { name: "NaCl 500ml", selected: false },
    { name: "NaCl 250ml", selected: false },
    { name: "Glucose 5% 1L", selected: false },
    { name: "Glucose 5% 500ml", selected: false },
    { name: "Voluven 6% 500ml", selected: false },
    { name: "Alburex 5%", selected: false },
    { name: "FFP", selected: false },
    { name: "Packed cells", selected: false },
    { name: "Cell saver", selected: false },
    { name: "Platelets", selected: false },
]

const catheter = [
    { name: "Venous line: ", dose: "20G", selected: true },
    { name: "Radial artery line: ", dose: "right", selected: false },
    { name: "Humeral artery line: ", dose: "right", selected: false },
    { name: "Femoral artery line: ", dose: "right", selected: false },
    { name: "Central line: ", dose: "Right internal jugular vein", selected: false },
    { name: "Bladder sonde", dose: "", selected: false },
    { name: "Swan Ganz: ", dose: "Right Internal jugular vein", selected: false },
]

const centralCatheter = [
    { name: "Right internal jugular vein catheter: ", dose: "3 lumen", selected: true },
    { name: "Left internal jugular vein catheter: ", dose: "3 lumen", selected: false },
    { name: "Right subclavian vein catheter: ", dose: "3 lumen", selected: false },
    { name: "Left subclavian vein catheter: ", dose: "3 lumen", selected: false },
    { name: "Right femoral vein catheter: ", dose: "3 lumen", selected: false },
    { name: "Left femoral vein catheter: ", dose: "3 lumen", selected: false },
]

const pediaCatheter = [
    { name: "Venous line: ", dose: "22G after induction", selected: true },
    { name: "Central line: ", dose: "Right internal jugular vein", selected: false },
    { name: "Bladder sonde", dose: "", selected: false },
    { name: "Swan Ganz: ", dose: "Right Internal jugular vein", selected: false },
]

const ventilation = [
    { name: "Manuel vent: ", dose: "easy", selected: true },
    { name: "Cormack: ", dose: "1", selected: true },
    { name: "AirTrac: ", dose: "6,5-7,5", selected: false },
    { name: "Cricoïd pressure ", dose: "", selected: false },
    { name: "Guiding sonde ", dose: "", selected: false },
    { name: "Orotracheal intubation: ", dose: "7,5 + cuff", selected: true },
    { name: "Nasotracheal intubation: ", dose: "6,0 + cuff", selected: false },
    { name: "Laryngeal mask: ", dose: "N°4", selected: false },
    { name: "No theeth or mucosal damage", dose: "", selected: true },
    { name: "Pressure controled Vol Gar: ", dose: "600ml 12/min peep:5cmH2O", selected: true },
    { name: "Pressure controled: ", dose: "15cmH2O 12/min peep:5cmH2O", selected: false },
    { name: "FiO2: ", dose: "40%", selected: true },
    { name: "N2O: ", dose: "50%", selected: false },
    { name: "Occular protection", dose: "", selected: true },
    { name: "Position: supine ", dose: "", selected: true },
    { name: "Position: lithotomy ", dose: "", selected: false },
    { name: "Position: lateral ", dose: "left / right", selected: false },
    { name: "Position prone: ", dose: "Head in a neutral position (no flexion, extension or rotation), head thorax and pelvis supported, eyes-nose-mouth area is free of any contact, endotracheal tube is secured and without traction", selected: false },
    { name: "Position: beach chair ", dose: "Head in a neutral position (no flexion, extension or rotation)", selected: false },
    { name: "Position: trendelenburg ", dose: "", selected: false },
    { name: "Position: reverse trendelenburg ", dose: "", selected: false },
    { name: "Arm tucked", dose: " at the patient’s side in the thumb-up position", selected: true },
    { name: "Arm abducted", dose: " less than 90 degrees with the forearm in supinated position, safety strap around the arm", selected: false },
    { name: "Arm fixated above the body", dose: ", less than 90 degrees flexion in elbow and shoulder: Left / Right", selected: false },
    { name: "Legs neutral position, padding for the heels", dose: "", selected: true },
    { name: "Legs neutral position, support under the lower legs, heels free of contact", dose: "", selected: false },
    { name: "Leg is secured in leg supports with additional padding combined intermittent pneumatic leg compression", dose: "left / right / both legs", selected: false },
    { name: "Additional padding for the pressure points; ", dose: "position of the patient discussed with the surgeon and nurse", selected: true },
    { name: "Forced-air warming", dose: "", selected: true },
    { name: "Fluids heater ", dose: "", selected: false },
    { name: "Placing a gastrointestinal probe at the surgeon's request. Under the surgeon's supervision, the probe is advanced without resistance", dose: "", selected: false },
]

const pediaVentilation = [
    { name: "Manuel vent: ", dose: "easy", selected: true },
    { name: "Cormack: ", dose: "1", selected: true },
    { name: "Cricoïd pressure ", dose: "", selected: false },
    { name: "Guiding sonde ", dose: "", selected: false },
    { name: "Orotracheal intubation: ", dose: "4,5 without cuff", selected: false },
    { name: "Nasotracheal intubation: ", dose: "4,5 without cuff", selected: true },
    { name: "Laryngeal mask: ", dose: "N°2", selected: false },
    { name: "Respiration: ", dose: "Spontaneous", selected: true },
    { name: "Local anesthesia: ", dose: "By the surgeon", selected: false },
    { name: "Pressure controled Vol Gar: ", dose: "600ml 12/min peep:5cmH2O", selected: false },
    { name: "Pressure controled: ", dose: "15cmH2O 12/min peep:5cmH2O", selected: false },
    { name: "FiO2: ", dose: "40%", selected: true },
    { name: "N2O: ", dose: "50%", selected: false },
    { name: "Occular protection", dose: "", selected: true },
    { name: "Position: ", dose: "supine", selected: true },
    { name: "Nervous protections: ", dose: "Peroneal nerve, cubital, plexus, pressure points ", selected: true },
    { name: "Forced-air warming", dose: "", selected: true },
    { name: "Fluids heater ", dose: "", selected: false },
    { name: "No theeth or mucosal damage", dose: "", selected: true },
]

const sedationVentilation = [
    { name: "Respiration: ", dose: "Spontaneous", selected: true },
    { name: "ETCO2: ", dose: "Nasal", selected: false },
    { name: "O2: ", dose: "2l/min", selected: true },
    { name: "Nervous protections: ", dose: "Peroneal nerve, cubital, plexus, pressure points ", selected: true },
    { name: "Forced-air warming", dose: "", selected: false },
    { name: "Local anesthesia: ", dose: "By the surgeon", selected: true },
]

const vcVentilation = [
    { name: "O2: ", dose: "2l/min", selected: true },
    { name: "Position: ", dose: "Trendelenbourg checked by the anaesthetist", selected: true },
]

const endoVentilation = [
    { name: "Position: ", dose: "Left lateral decubitus", selected: true },
    { name: "Respiration: ", dose: "Spontaneous", selected: true },
    { name: "O2: ", dose: "3l/min", selected: true },
    { name: "Nervous protections: ", dose: "Peroneal nerve, cubital, plexus, pressure points ", selected: true },
]

const laryngealVentilation = [
    { name: "Manuel vent: ", dose: "easy", selected: true },
    { name: "Laryngeal mask: ", dose: "N°4", selected: true },
    { name: "No theeth or mucosal damage", dose: "", selected: true },
    { name: "Pressure controled Vol Gar: ", dose: "600ml 12/min peep:5cmH2O", selected: true },
    { name: "Pressure controled: ", dose: "15cmH2O 12/min peep:5cmH2O", selected: false },
    { name: "FiO2: ", dose: "40%", selected: true },
    { name: "N2O: ", dose: "50%", selected: false },
    { name: "Occular protection", dose: "", selected: true },
    { name: "Position: ", dose: "supine", selected: true },
    { name: "Position: lithotomy ", dose: "", selected: false },
    { name: "Position: beach chair ", dose: "Head in a neutral position (no flexion, extension or rotation)", selected: false },
    { name: "Position: trendelenburg ", dose: "", selected: false },
    { name: "Position: reverse trendelenburg ", dose: "", selected: false },
    { name: "Arm tucked", dose: " at the patient’s side in the thumb-up position", selected: true },
    { name: "Arm abducted", dose: " less than 90 degrees with the forearm in supinated position, safety strap around the arm", selected: false },
    { name: "Arm fixated above the body", dose: ", less than 90 degrees flexion in elbow and shoulder: Left / Right", selected: false },
    { name: "Legs neutral position, padding for the heels", dose: "", selected: true },
    { name: "Legs neutral position, support under the lower legs, heels free of contact", dose: "", selected: false },
    { name: "Additional padding for the pressure points; ", dose: "position of the patient discussed with the surgeon and nurse", selected: true },
    { name: "Forced-air warming", dose: "", selected: true },
    { name: "Fluids heater ", dose: "", selected: false },
    ]

const rachiDrugs = [
    { name: "Peripheral venus: ", dose: "20G", selected: true },
    { name: "Central access: ", dose: "Right Internal jugularis", selected: false },
    { name: "Bladder sonde", dose: "", selected: false },
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Propofol(mg)", dose: "20", selected: false },
    { name: "Propofol(µg/cc)", dose: "1.0", selected: false },
    { name: "Sufentanil(µg)", dose: "5", selected: false },
    { name: "Lidocain(mg)", dose: "60", selected: false },
    { name: "Dexamethason(mg)", dose: "5", selected: false },
    { name: "Cefazolin(g)", dose: "2", selected: true },
    { name: "Augmentin(g)", dose: "2", selected: false },
    { name: "Flagyl(g)", dose: "1,5", selected: false },
    { name: "Amikacine(g)", dose: "1", selected: false },
]

const rachiPonction = [
    { name: "O2: ", dose: "2l/min", selected: true },
    { name: "Position: ", dose: "Seated", selected: true },
    { name: "Desinfection: ", dose: "Chlorexidine 0.5%", selected: true },
    { name: "Level: ", dose: "L3-L4", selected: true },
    { name: "Local anesthesia: ", dose: "Lidocaïne 2%: 5cc", selected: true },
    { name: "Needle: ", dose: "Whitacre: 27G", selected: true },
    { name: "Number of attempt: ", dose: "1, easy, CSF+", selected: true },
    { name: "Marcaïne hyperbare: ", dose: "12mg (3cc) + Sufentanil: 3µg", selected: true },
    { name: "Tachipri: ", dose: "60mg (3cc)", selected: false },
]

const periPonction = [
    { name: "Position: ", dose: "Seated", selected: true },
    { name: "Desinfection: ", dose: "Chlorexidine 0.5%", selected: true },
    { name: "Level: ", dose: "L3-L4", selected: true },
    { name: "Local anesthesia: ", dose: "Lidocaïne 2%: 5cc", selected: true },
    { name: "Needle: ", dose: "Tuohy 18G", selected: true },
    { name: "Catheter: ", dose: "20G", selected: true },
    { name: "Number of attempt: ", dose: "1, easy, no blood or csf in the catheter", selected: true },
    { name: "Blood in the catheter: ", dose: "", selected: false },
    { name: "Loss of resistance: ", dose: "5 cm", selected: true },
    { name: "Catheter at: ", dose: "9 cm", selected: true },
    { name: "Test Dose: Marcaïne ", dose: "0,125% + Adré (2,5µ/cc): 10ml", selected: false },
    { name: "Sufentanil: ", dose: "7,5µg", selected: false },
    { name: "Naropin ", dose: "0,16% + Sufentanil 10µg: 10ml", selected: true },
]

const regionalDrugs = [
    { name: "Peripheral venus: ", dose: "20G", selected: true },
    { name: "Midazolam(mg): ", dose: "1", selected: true },
    { name: "Clonidine(µg): ", dose: "75", selected: false },
    { name: "Ketamin(mg): ", dose: "25", selected: false },
    { name: "Propofol(mg)", dose: "20", selected: true },
    { name: "Propofol(µg/cc)", dose: "1.0", selected: false },
    { name: "Sufentanil(µg)", dose: "5", selected: false },
    { name: "Cefazolin(g)", dose: "2", selected: true },
    { name: "Augmentin(g)", dose: "2", selected: false },
]

const regionalPonction = [
    { name: "Bloc: ", dose: "axillary", selected: true },
    { name: "Right side ", dose: "", selected: true },
    { name: "Left side ", dose: "", selected: false },
    { name: "Desinfection: ", dose: "Chlorexidine 0.5%", selected: true },
    { name: "Type echo machine: ", dose: "Sonosite", selected: true },
    { name: "Ultrasound-guided needle insertion ", dose: "in plane", selected: true },
    { name: "Neuro-stimulated ponction ", dose: "", selected: true },
    { name: "Needle: ", dose: "Pajunk 100mm", selected: true },
    { name: "Stimulated nerve (0.5mA): ", dose: "Medial, radial, cubital, Musculo-cutaneous", selected: true },
    { name: "Place at injection at (cm): ", dose: " ", selected: false },
    { name: "No paresthesia, pressure resistance or pain at injection ", dose: "", selected: true },
    { name: "Brief episode of paresthesia, needle withdrawn until paresthesia disappears, good visualization of injection site, no resistance to injection ", dose: "", selected: false },
    { name: "Structure visualisation: ", dose: "good", selected: true },
    { name: "Frequent aspiration", dose: "", selected: true },
    { name: "Naropin 0.5% + Xylocaïne 0.5% + Adre(2,5µg/cc): ", dose: "30cc", selected: true },
    { name: "Naropin ", dose: "0,25%: 30cc", selected: false },
    { name: "Scandicaïne 1% + Adre(5µg/cc) : ", dose: "30cc", selected: false },
    { name: "Xylocaïne ", dose: "1%: 20cc", selected: false },
    { name: "O2: ", dose: "2l/min", selected: false },
    { name: "Informed consent regional anesthesia: ", dose: "OK", selected: true },
]

const regionalAGPonction = [
    { name: "Block: ", dose: "interscalenic", selected: true },
    { name: "Right side ", dose: "", selected: true },
    { name: "Left side ", dose: "", selected: false },
    { name: "Desinfection: ", dose: "Chlorexidine 0.5%", selected: true },
    { name: "Echoguided ponction ", dose: "", selected: true },
    { name: "Neuro-stimulated ponction ", dose: "", selected: true },
    { name: "Needle: ", dose: "Pajunk 50mm", selected: true },
    { name: "Stimulated nerve (0.5mA): ", dose: "Deltoïd", selected: true },
    { name: "No pain at injection ", dose: "", selected: true },
    { name: "Structure visualisation: ", dose: "good", selected: true },
    { name: "Frequent aspiration", dose: "", selected: true },
    { name: "Naropin 0.5% + Xylocaïne 0.5% + Adre(2,5µg/cc): ", dose: "20cc", selected: false },
    { name: "Naropin ", dose: "0,5%: 20cc", selected: true },
    { name: "Scandicaïne 1% + Adre(5µg/cc) : ", dose: "30cc", selected: false },
    { name: "Xylocaïne ", dose: "1%: 20cc", selected: false },
    { name: "O2: ", dose: "2l/min", selected: false },
]

const reveilData = [
    { name: "Paracetamol: ", dose: "1g IV à donner d'office", selected: false },
    { name: "Paracetamol: ", dose: "1g IV si nécessaire", selected: false },
    { name: "Ibuprofen: ", dose: "400mg IV à donner d'office", selected: false },
    { name: "Ibuprofen: ", dose: "400mg IV si nécessaire", selected: false },
    { name: "Naproxene: ", dose: "500mg PO si nécessaire", selected: false },
    { name: "Contramal: ", dose: "100mg avec Litican 50mg à donner d'office", selected: false },
    { name: "Contramal: ", dose: "100mg avec Litican 50mg si nécessaire", selected: false },
    { name: "Tradonal Odis: ", dose: "1co si nécessaire", selected: false },
    { name: "Morphine Intraveineux: ", dose: "Titrer par 2 mg si nécessaire (Max 8mg)", selected: false },
    { name: "Si nausées ou vomissements: ", dose: "Ondansetron 4 mg IV", selected: true },
    { name: "A reçu: ", dose: "Dexamethasone, DHB, Ondansetron, Catapressan", selected: false },
    { name: "Plaie(s) infiltrée(s) par le chirugien ", dose: "", selected: false },
    { name: "PCA Morphine: ", dose: "1mg/cc", selected: false },
    { name: "Peridurale: ", dose: "", selected: false },
]

const remarkData = [
    { name: "Glycemie à doser", dose: "", selected: false },
    { name: "Décharge du patient sans prévenir l'anesthésiste si les critères de sortie sont validés", dose: "", selected: false },
    { name: "", dose: "", selected: false },
    { name: "", dose: "", selected: false },

]

const comments = [
    { name: "Temperature:", dose: "36,0" },
    { name: "%TOF:", dose: "90" },
    { name: "Total urinary flow(ml):", dose: "400" },
    { name: "BIS:", dose: "45" },
    { name: "INVOS L/R:", dose: "60/60" },
    { name: "Start tourniquet", dose: "" },
    { name: "End tourniquet", dose: "" },
    { name: "PAP S/d(mmHg):", dose: "25/12" },
    { name: "SvO2(%):", dose: "70" },
    { name: "IC (L/min/m2):", dose: "2,5" },
    { name: "ACT(sec):", dose: "100" },
    { name: "Start ECC", dose: "" },
    { name: "End ECC", dose: "" },
    { name: "Aortic clamping", dose: "" },
    { name: "Aortic unclamping", dose: "" },
]

const oneDayData = [
    { name: "30 minutes après l'arrivée du patient et avant sa sortie, noter les paramètres vitaux (fréquence cardiaque, saturation, tension artérielle) ainsi que le score de douleur", dose: "", selected: true },
    { name: "\xa0", dose: "", selected: true },
    { name: "Peut manger et boire dès son retour en chambre", dose: "", selected: true },
    { name: "Peut boire à ", dose: "", selected: false },
    { name: "Peut manger à ", dose: "", selected: false },
    { name: "Si douleur: ", dose: "Voir prochaines doses", selected: true },
    { name: "Analgésique supplémentaire si nécessaire: ", dose: "Tradonal odis 1co", selected: false },
    { name: "Si nausées ou vomissements: ", dose: "Ondansetron 4 mg IV", selected: true },
    { name: "Doit avoir uriné avant de quitter la clinique ", dose: "", selected: false },
    { name: "Vérifier la sensibilité et la motricité des jambes", dose: "", selected: false },
    { name: "Décharge du patient sans prévenir l'anesthésiste si les critères de sortie sont validés", dose: "", selected: false },
]

export {
    fluids, drugs, rachiDrugs, regionalDrugs, laryngealDrugs, rachiPonction, regionalPonction,
    ventilation, laryngealVentilation, monitoring, catheter, centralCatheter, pediaCatheter, reveilData, remarkData, sedationDDSDrugs,
    sedationDrugs, regionalAGPonction, periPonction,
    sedationVentilation, endoDrugs, endoVentilation, pediaVentilation,vcVentilation, pediaDrugs, comments, oneDayData
};