import React from 'react';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';

function MonitoringList(props) {
    const [monitoring, setMonitoring] = useState(props.monitoring);

    function handleCheck(event) {
        const check = event.target.checked;

        const newMonitoring = monitoring;

        newMonitoring.selected = check;

        setMonitoring(prevValue => {
            return {
                ...prevValue, selected: check
            }
        });
        props.send(newMonitoring, props.index)

    }

    return (
        <Paper elevation={0}>
            <FormControlLabel control={<Checkbox checked={monitoring.selected} name={monitoring.name} onChange={handleCheck} />} label={monitoring.name} />

        </Paper>)

}

export default MonitoringList;