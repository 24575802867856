import React from 'react';
import Typography from '@mui/material/Typography';

function Heure(props) {
    const dateAleatoire = new Date();

    dateAleatoire.setTime(props.heure);

    const heure = dateAleatoire.toLocaleTimeString("fr-BE" , {hour:"2-digit", minute:"2-digit"});
    return (
        <Typography variant="button">
            {heure}
        </Typography>
    )

};

export default Heure;