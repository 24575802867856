import React, { useState, Fragment } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

function PopRecu({ analgesiqueRecu, setAnalgesiqueRecu, close }) {

    const [analgesiqueModifie, setAnalgesiqueModifie] = useState(analgesiqueRecu)

    function handleClick() {
        close();
    }

    function handleSend(){
        setAnalgesiqueRecu(analgesiqueModifie)
        close();
    }

    function handleChange(e, i){
        
        const clonedAnalgesiqueModifie = [...analgesiqueModifie];
        clonedAnalgesiqueModifie[i] = e.target.value;
        
        setAnalgesiqueModifie(clonedAnalgesiqueModifie)
        
    }
    return (
        <div>
            <Dialog
                open={true}
                fullWidth
                maxWidth='sm'
            >

                <DialogTitle>Analgésiques reçus</DialogTitle>
                <DialogContent dividers>
                {analgesiqueModifie.map((analgesique,i)=>{
                    return(
                            <Fragment key={i}>
                                <Grid>
                                <TextField variant="standard" style={{width: 450}} onChange={(e)=>handleChange(e,i)} value={analgesique} />
                                </Grid>
                            </Fragment>
                    )
                })}

                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSend}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PopRecu;