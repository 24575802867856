import React from 'react';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PopReveil from './TypeOfAnesthesia/PopUpInduction/PopReveil';
import { reveilData, remarkData } from './TypeOfAnesthesia/DataProtocol';


function Reveil({patientName}) {
    
    const [reveilIsClicked, setReveilIsClicked] = useState(false);
    const [remarkIsClicked, setRemarkIsClicked] = useState(false);
    const [reveil, setReveil] = useState(reveilData);
    const [remark, setRemark] = useState(remarkData);

    function handleReveil() {
        setReveilIsClicked(true);
    }

    function handleRemark() {
        setRemarkIsClicked(true);
    }

    function addReveil(chosenReveil) {
        setReveil(chosenReveil)
    }

    function addRemark(chosenRemark) {
        setRemark(chosenRemark)
    }

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Paper >
                        <Typography display="inline" variant="h6">PACU </Typography>
                        <IconButton style={{color:"#039BFF"}} onClick={handleReveil} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        {reveilIsClicked &&
                            <PopReveil
                                rachiPonction={reveil}
                                titre="PACU"
                                chosenPonction={addReveil}
                                close={() => setReveilIsClicked(false)}
                            />
                        }
                        {reveil.map((item, index) => {
                            return item.selected && (
                                <Typography key={index} variant="body1" >{item.name} {item.dose} </Typography>
                            )
                        })}
                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper >
                        <Typography display="inline" variant="h6">Remarques </Typography>
                        <IconButton style={{color:"#039BFF"}} onClick={handleRemark} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        {remarkIsClicked &&
                            <PopReveil
                                rachiPonction={remark}
                                titre="Remarques"
                                chosenPonction={addRemark}
                                close={() => setRemarkIsClicked(false)}
                            />
                        }
                        {remark.map((item, index) => {
                            return item.selected && (
                                <Typography key={index} variant="body1" >{item.name} {item.dose} </Typography>
                            )
                        })}
                    </Paper>
                </Grid>

                <Grid item xs={4}>
                    <Paper sx={{height:160}} >
                        <Typography display="inline" variant="h6">Patient ID </Typography>
                        <br/>
                        <br/>
                        <Typography variant="h4">{patientName}</Typography>
                    </Paper>
                </Grid>

            </Grid>

        </div>
    )
}

export default Reveil;