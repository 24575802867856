import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';


function ListDrugs(props) {


    const drugs = [
        { name: "Propofol(mg): ", dose: "10" },
        { name: "Propofol 1%(cc/h): ", dose: "20" },
        { name: "Propofol TCI(µg/cc): ", dose: "2,0" },
        { name: "Sufenta(µg): ", dose: "5" },
        { name: "Midazolam(mg): ", dose: "1" },
        { name: "Esmeron(mg): ", dose: "10" },
        { name: "Clonidine(µg): ", dose: "75" },
        { name: "Ephedrine(mg): ", dose: "6" },
        { name: "Cefazolin(g): ", dose: "2" },
        { name: "Dalacin(mg): ", dose: "600" },
        { name: "Atropine(mg): ", dose: "0,5" },
        { name: "Cisatracurium(mg): ", dose: "4" },
        { name: "Noradrenaline(8µg/cc) (cc/h): ", dose: "10" },
        { name: "Noradrenaline(100µg/cc) (cc/h): ", dose: "2" },
        { name: "Ondansetron(mg): ", dose: "4" },
        { name: "Phenylephrine(µg): ", dose: "100" },
        { name: "Heparine(U): ", dose: "5000" },
        { name: "Ketamine(mg): ", dose: "25" },
        { name: "Paracetamol(g): ", dose: "1" },
        { name: "Ibuprofen(mg): ", dose: "400" },
        { name: "Contramal(mg): ", dose: "100" },
        { name: "DHB(mg): ", dose: "1,25" },
        { name: "Solumedrol(mg): ", dose: "40" },
        { name: "Remifentanil TCI(µg/cc): ", dose: "3" },
        { name: "Robinul/Neostigmine(mg): ", dose: "0,5/2,5" },
        { name: "Suggamadex(mg): ", dose: "200" },
        { name: "Solucortef(mg): ", dose: "100" },
        { name: "Dexamethason(mg): ", dose: "10" },
        { name: "Buscopan(mg): ", dose: "20" },
        { name: "Lasix(mg): ", dose: "10" },
        { name: "Morphine(mg): ", dose: "2" },
        { name: "Sevoflurane(%ET): ", dose: "1,8" },
        { name: "Protamine(1200U/ml) ", dose: "25 ml " },
        { name: "Calcium Gluconate(g): ", dose: "2" },
        { name: "Exacyl(g): ", dose: "1,5" },
        { name: "MgSO4(g): ", dose: "2" },
        { name: "Pabal(µg): ", dose: "100" },
        { name: "Ocytocin(U): ", dose: "3" },
    ];


    drugs.sort((a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return +1;
        }
        return 0;
    }
    );




    const [isClicked, setIsClicked] = useState(false);
    const [showDosage, setShowDosage] = useState(false);
    const [hemoDrug, setHemoDrug] = useState(props.hemoDrug)
    const [drugAndDose, setDrugAndDose] = useState({
        index: 0,
        name: "",
        dose: ""
    }
    )


    function handleClick() {
        setIsClicked(!isClicked);
    }

    function handleSend() {
        setIsClicked(!isClicked);
        const drugAndDoseString = hemoDrug + "\xa0\xa0" + drugAndDose.name + " " + drugAndDose.dose;
        
        if (drugAndDose.name === "Paracetamol(g): " ) {
            const dateAleatoire = new Date();
            const date6h = new Date();
            dateAleatoire.setTime(props.heure);
            date6h.setTime(props.heure+21600000)
            const heureString = dateAleatoire.toLocaleTimeString("fr-BE", { hour: "2-digit", minute: "2-digit" });
            const heureString6h = date6h.toLocaleTimeString("fr-BE", { hour: "2-digit", minute: "2-digit" });
            props.addAnalgesique(heureString + " " + drugAndDose.name + " " + drugAndDose.dose  + "\xa0\xa0\xa0  Prochaine dose à " + heureString6h)
        }
        if (drugAndDose.name === "Contramal(mg): ") {
            const dateAleatoire = new Date();
            dateAleatoire.setTime(props.heure);
            const heureString = dateAleatoire.toLocaleTimeString("fr-BE", { hour: "2-digit", minute: "2-digit" });
            props.addAnalgesique(heureString + " " + drugAndDose.name + " " + drugAndDose.dose)
        }
        if (drugAndDose.name === "Ibuprofen(mg): " ) {
            const dateAleatoire = new Date();
            const date8h = new Date();
            dateAleatoire.setTime(props.heure);
            date8h.setTime(props.heure+28800000)
            const heureString = dateAleatoire.toLocaleTimeString("fr-BE", { hour: "2-digit", minute: "2-digit" });
            const heureString8h = date8h.toLocaleTimeString("fr-BE", { hour: "2-digit", minute: "2-digit" });
            props.addAnalgesique(heureString + " " + drugAndDose.name + " " + drugAndDose.dose + "\xa0\xa0\xa0 Prochaine dose à " + heureString8h)
        }

        props.drug(drugAndDoseString);

        setDrugAndDose({
            index: 0,
            name: "",
            dose: ""
        })
    }

    function handleDrug(drug, index) {
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                name: drug.name,
                dose: drug.dose,
                index: index
            }
        })
        setShowDosage(true);
    }

    function handleChange(event) {
        const value = event.target.value;
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                dose: value
            }
        })
    }

    function handleChangeDrug(event) {
        const value = event.target.value;
        setHemoDrug(value)
    }

    return (
        <React.Fragment>
            <IconButton sx={{ padding: 0 }} onClick={handleClick} style={{ color: "gray" }} >
                <EditIcon sx={{
                    fontSize: "0.7rem",
                    padding: 0
                }} />
            </IconButton>
            <Dialog 
                disableEscapeKeyDown
                style={{ maxWidth: 600 }}
                open={isClicked}

            >
                <DialogTitle>Drug: {hemoDrug} &nbsp;{drugAndDose.name} {drugAndDose.dose}</DialogTitle>
                <DialogContent dividers>
                    <TextField variant="standard" placeholder="Free text" style={{ marginLeft: '20px', width: '90%' }} value={hemoDrug} onChange={handleChangeDrug} />
                    <Grid style={{ padding: '10px' }} container >
                        {
                            drugs.map((drug, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={7}>

                                            <List>
                                                <ListItem button style={{ width: "90%" }} name={drug.name} onClick={() => handleDrug(drug, index)}>
                                                    <ListItemText primary={drug.name} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        {showDosage === true && index === drugAndDose.index ?
                                            <Grid item xs={4}>
                                                <TextField variant="standard" label="Dosage" onChange={handleChange} value={drugAndDose.dose} />
                                            </Grid> : null}
                                    </Fragment>
                                )
                            })}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSend}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default ListDrugs;