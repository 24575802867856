import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Typography } from '@mui/material';



function Fluid({ totalFluid, setTotalFluid, close }) {

    const fluids = [
        { name: "SteroIso ", dose: "1000ml" },
        { name: "Plasmalyte ", dose: "1000ml" },
        { name: "Plasmalyte 500ml + metrisette", dose: "" },
        { name: "Plasmalyte 500ml + dialaflow", dose: "" },
        { name: "NaCl ", dose: "1000ml" },
        { name: "Glucose 5% ", dose: "500ml" },
        { name: "Voluven 6% ", dose: "500ml" },
        { name: "Alburex 5%", dose: "500ml" },
        { name: "Cell saver", dose: "ml" },
        { name: "FFP", dose: "200ml" },
        { name: "Packed cells", dose: "n° ml" },
        { name: "Platelets", dose: "n° 8U" },
    ]

    
    const [showDosage, setShowDosage] = useState(false);
    const [hemoDrug, setHemoDrug] = useState(totalFluid[totalFluid.length - 1])
    const [drugAndDose, setDrugAndDose] = useState({
        index: 0,
        name: "",
        dose: ""
    }
    )


    function handleClick() {
        close();
    }

    function handleSend() {
        const newTotalFluid = totalFluid
        let drugAndDoseString = drugAndDose.name + " " + drugAndDose.dose
        if (newTotalFluid.length > 0) {
            newTotalFluid.pop()
            newTotalFluid.push(hemoDrug)
        }

        if (drugAndDoseString !== " ") {
            const n = new Date();
            const heure = n.toLocaleString("fr-BE", { timeStyle: "short" });
            drugAndDoseString = heure + "\xa0\xa0" + drugAndDoseString
            newTotalFluid.push(drugAndDoseString);
        }

        setTotalFluid(newTotalFluid);


        setDrugAndDose({
            index: 0,
            name: "",
            dose: ""
        })
        close();
    }

    function handleDrug(drug, index) {
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                name: drug.name,
                dose: drug.dose,
                index: index
            }
        })
        setShowDosage(true);
    }

    function handleChange(event) {
        const value = event.target.value;
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                dose: value
            }
        })
    }

    function handleChangeDrug(event) {
        const value = event.target.value;
        setHemoDrug(value)
    }

    return (
        <React.Fragment>

            <Dialog disablebackdropclick="true"
                disableEscapeKeyDown
                style={{ maxWidth: 600 }}
                open= {true}
            >
                <DialogTitle>Drug: {drugAndDose.name} {drugAndDose.dose}</DialogTitle>
                <DialogContent dividers>
                    {totalFluid.length > 0 &&
                        <React.Fragment>
                            <Typography style={{ marginLeft: '20px', display: 'inline' }}><b>Last fluid: </b></Typography>
                            <TextField variant="standard" placeholder="Free text" style={{ marginLeft: '20px', width: '70%' }} value={hemoDrug} onChange={handleChangeDrug} />
                        </React.Fragment>
                    }

                    <Grid style={{ padding: '10px' }} container >
                        {
                            fluids.map((drug, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={7}>

                                            <List>
                                                <ListItem button style={{ width: "90%" }} name={drug.name} onClick={() => handleDrug(drug, index)}>
                                                    <ListItemText primary={drug.name} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        {showDosage === true && index === drugAndDose.index ?
                                            <Grid item xs={4}>
                                                <TextField variant="standard" label="Dosage" onChange={handleChange} value={drugAndDose.dose} />
                                            </Grid> : null}
                                    </Fragment>
                                )
                            })}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSend}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default Fluid;