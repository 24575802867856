import React, { Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { comments } from "./TypeOfAnesthesia/DataProtocol";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


function Comments(props) {

    const [isClicked, setIsClicked] = useState(false);
    const [showDosage, setShowDosage] = useState(false);
    const [hemoComment, setHemocomment] = useState(props.hemoComment)
    const [drugAndDose, setDrugAndDose] = useState({
        index: 0,
        name: "",
        dose: ""
    }
    )


    function handleClick() {
        setIsClicked(!isClicked);
    }

    function handleSend() {
        setIsClicked(!isClicked);
        let drugAndDoseString = ""
        drugAndDoseString = hemoComment + "\xa0\xa0" + drugAndDose.name + " " + drugAndDose.dose;

        props.comment(drugAndDoseString);
        setDrugAndDose({
            index: 0,
            name: "",
            dose: ""
        })

    }

    function handleDrug(drug, index) {
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                name: drug.name,
                dose: drug.dose,
                index: index
            }
        })
        setShowDosage(true);
    }

    function handleChange(event) {
        const value = event.target.value;
        setDrugAndDose(prevValue => {
            return {
                ...prevValue,
                dose: value
            }
        })
    }

    function handleChangeComment(event) {
        const value = event.target.value;
        setHemocomment(value)
    }

    return (
        <React.Fragment>
            <IconButton sx={{padding:0}} onClick={handleClick} style={{ color: "gray" }} >
                <EditIcon sx={{
                    fontSize: "0.7rem",
                    padding: 0
                }} />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth="md"
                open={isClicked}
            >

                <DialogTitle>Comments: {hemoComment} &nbsp; {drugAndDose.name} {drugAndDose.dose}</DialogTitle>
                <DialogContent dividers>
                    <TextField variant="standard" placeholder="Free text" style={{ marginLeft: '20px', width: '90%' }} value={hemoComment} onChange={handleChangeComment} />
                    <Grid style={{ padding: '10px' }} container >

                        {
                            comments.map((drug, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={7}>

                                            <List>
                                                <ListItem button style={{ width: "90%" }} name={drug.name} onClick={() => handleDrug(drug, index)}>
                                                    <ListItemText primary={drug.name} />
                                                </ListItem>
                                            </List>
                                        </Grid>

                                        {showDosage === true && index === drugAndDose.index ?
                                            <Grid item xs={4}>
                                                <TextField variant="standard" label="" onChange={handleChange} value={drugAndDose.dose} />
                                            </Grid> : null}
                                    </Fragment>
                                )
                            })}


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={handleSend}>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )
}

export default Comments;