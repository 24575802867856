import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import GeneralProtocol from "./TypeOfAnesthesia/GeneralProtocol";
import RachiProtocol from './TypeOfAnesthesia/RachiProtocol';
import RegionalProtocol from './TypeOfAnesthesia/RegionalProtocol';
import LaryngealProtocol from './TypeOfAnesthesia/LaryngealProtocol';
import SedationProtocol from './TypeOfAnesthesia/SedationProtocol';
import SedationDDSProtocol from './TypeOfAnesthesia/SedationDDSProtocol';
import EndoProtocol from './TypeOfAnesthesia/EndoProtocol';
import PediaProtocol from './TypeOfAnesthesia/PediaProtocol';
import GeneralBlocProtocol from "./TypeOfAnesthesia/GeneralBlocProtocol";
import GeneralPeriProtocol from "./TypeOfAnesthesia/GeneralPeriProtocol";
import PeriProtocol from "./TypeOfAnesthesia/PeriProtocol";
import VoieCentraleProtocol from "./TypeOfAnesthesia/VoieCentraleProtocol";
import { Typography } from '@mui/material';



function InductionProtocol({ setCo2IsShow, setExtubIsShow, setCardiacParamIsShow, totalFluid, setTotalFluid }) {
    const [protocol, setProtocol] = useState("");

    function handleProtocol(event) {
        const proto = event.target.value;
        setProtocol(event.target.value);
        if (proto === "sedation" || proto === "sedationDDS" || proto === "endo" || proto === "rachi" || proto === "alr" || proto === "peri") {
            setCo2IsShow(false)
            setExtubIsShow(false)
            setCardiacParamIsShow(false)
        }
        else {
            setCo2IsShow(true)
            setExtubIsShow(true)
            setCardiacParamIsShow(false)
        }

        if (proto === "cardiac") {
            setCardiacParamIsShow(true)
        }

        if (proto === "pedia") {
            setTotalFluid(["Plasmalyte 500ml + metrisette"])
        }
    }

    return (
        <div className="induction">

            <Grid container >
                <FormControl variant="outlined" size="small" >

                    <Select
                        value={protocol}
                        onChange={handleProtocol}
                        displayEmpty
                    >
                        <MenuItem value="" disabled >Select Anesthesia</MenuItem>
                        <MenuItem value={"ag"}>General Anesthesia</MenuItem>
                        <MenuItem value={"agalr"}>General Anesthesia + Regional Anesthesia</MenuItem>
                        <MenuItem value={"agperi"}>General Anesthesia + Epidural</MenuItem>
                        <MenuItem value={"cardiac"}>Cardiac Anesthesia</MenuItem>
                        <MenuItem value={"pedia"}>Pediatric Anesthesia</MenuItem>
                        <MenuItem value={"lm"}>Laryngeal Mask</MenuItem>
                        <MenuItem value={"sedation"}>Sedation</MenuItem>
                        <MenuItem value={"sedationDDS"}>Sedation Wisdom Tooth</MenuItem>
                        <MenuItem value={"rachi"}>Spinal Anesthesia</MenuItem>
                        <MenuItem value={"peri"}>Epidural Anesthesia</MenuItem>
                        <MenuItem value={"alr"}>Regional Anesthesia</MenuItem>
                        <MenuItem value={"endo"}>Endoscopy</MenuItem>
                        <MenuItem value={"vc"}>Central venous catheter</MenuItem>
                    </Select>
                </FormControl>
            </Grid>


            {protocol === "rachi" &&
                <RachiProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "peri" &&
                <PeriProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "ag" &&
                <GeneralProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "agalr" &&
                <GeneralBlocProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "agperi" &&
                <GeneralPeriProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "cardiac" &&
                <GeneralProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "pedia" &&
                <PediaProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "alr" &&
                <RegionalProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "lm" &&
                <LaryngealProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "sedationDDS" &&
                <SedationDDSProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "sedation" &&
                <SedationProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "endo" &&
                <EndoProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            {protocol === "vc" &&
                <VoieCentraleProtocol
                    totalFluid={totalFluid}
                    setTotalFluid={setTotalFluid}
                />}

            <Box sx={{ marginTop: '5px' }}>
                <Typography variant='caption'  >Ponctions and catheters performed according to the current rules of sterility to protect the patient from infection and complications</Typography>
            </Box>


        </div >

    )
}

export default InductionProtocol;