import React, { useState } from "react";
import { Typography, Paper, Divider ,IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import PopRecu from './TypeOfAnesthesia/PopUpInduction/PopRecu';


function Recu({ analgesiqueRecu, setAnalgesiqueRecu }) {
    
    
    const [editAnalgesique, setEditAnalgesique] = useState(false)


    function handleRecu() {
        setEditAnalgesique(true)
    }

    return (
        <div>
            
                <Paper sx={{width:"40%"}} >
                    <Typography variant="h6">A reçu en salle d'opération et prochaines doses  
                    <IconButton style={{ color: "#039BFF" }} onClick={handleRecu} size="small" >
                            <EditIcon />
                        </IconButton>
                        </Typography>
                    <Divider />
                    {analgesiqueRecu.map((item, index) => {
                        return (
                            <Typography key={index} variant="body1" >{item} </Typography>
                        )
                    })}

                    {editAnalgesique &&
                    <PopRecu 
                    analgesiqueRecu= {analgesiqueRecu}
                    setAnalgesiqueRecu = {setAnalgesiqueRecu}
                    close= {()=> setEditAnalgesique(false)}
                    />
                    }
                </Paper>
            
        </div>
    )
}

export default Recu;