import React, { useState } from 'react';
import './App.css';
import Button from '@mui/material/Button';
import Clock from './Components/Clock';
import Patient from './Components/Patient';
import InductionProtocol from './Components/InductionProtocol';
import StartMonitoring from './Components/StartMonitoring.jsx';
import StartMonitoRestored from './Components/StartMonitoRestored'
import ExtubationButton from './Components/ExtubationButton';
import Entetecol from './Components/Entetecol';
import Grid from '@mui/material/Grid';
import TaSlider from './Components/TaSlider';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Typography } from '@mui/material';
import Recu from './Components/Recu';
import Reveil from './Components/Reveil';
import OneDay from './Components/OneDay';

function App() {

  const [tendancesPat, setTendancesPat] = useState([]);
  const [patientName, setPatientName] = useState("");
  const [isCut, setIsCut] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [co2IsShow, setCo2IsShow] = useState(true);
  const [extubIsShow, setExtubIsShow] = useState(true);
  const [cardiacParamIsShow, setCardiacParamIsShow] = useState(false);
  const [totalFluid, setTotalFluid] = useState(["SteroIso 1000ml"]);
  const [anesName, setAnes] = useState("");
  const [isInduced, setIsInduced] = useState(false);
  const [isRestored, setIsRestored] = useState(false);
  const [heureInd, setHeureInd] = useState();
  const [heureParamCard, setHeureParamCard] = useState();
  const [interval, setMin] = useState(5);
  const [analgesiqueRecu, setAnalgesiqueRecu] = useState([]);


  function restore(nameRestored) {
    const storedTrend = JSON.parse(localStorage.getItem(nameRestored))
    const n = new Date();
    const nMsec = storedTrend[0].heureMsec;
    setHeureParamCard(storedTrend[0].heureMsec)
    n.setTime(nMsec);
    setHeureInd(n.toLocaleString("fr-BE", { timeStyle: "short" }));
    setTendancesPat(storedTrend)
    setPatientName(nameRestored)
    setIsRestored(true)
    setIsInduced(true)
  }

  function handleAnes(anes) {
    setAnes(anes);
    document.title = patientName;
  }

  function addParam(ta) {
    setTendancesPat(prevValue => {
      return [...prevValue, ta]
    });
    setIsInduced(true);
    setHeureParamCard(ta.heureMsec)

  }

  function addTend(ta) {
    const n = new Date();
    const nMsec = n.getTime();
    const newHour = ta.heureMsec + (interval * 60000);
    if (nMsec > newHour) {

      if (cardiacParamIsShow) {

        if (newHour - heureParamCard >= 1800000) {

          const commentCard = tendancesPat.find(param => {
            return param.heureMsec === heureParamCard
          })
          console.log(commentCard)
          setTendancesPat(prevValue => {
            return [...prevValue,
            {
              heureMsec: newHour,
              syst: ta.syst,
              dias: ta.dias,
              fc: ta.fc,
              satu: ta.satu,
              co2: ta.co2,
              papSys: ta.papSys,
              papDias: ta.papDias,
              drug: "",
              comment: commentCard.comment
            }
            ];
          });
          setHeureParamCard(newHour)

        } else {
          setTendancesPat(prevValue => {
            return [...prevValue,
            {
              heureMsec: newHour,
              syst: ta.syst,
              dias: ta.dias,
              fc: ta.fc,
              satu: ta.satu,
              co2: ta.co2,
              papSys: ta.papSys,
              papDias: ta.papDias,
              drug: "",
              comment: ""
            }
            ];
          });
        }
      }

      else {
        setTendancesPat(prevValue => {
          return [...prevValue,
          {
            heureMsec: newHour,
            syst: ta.syst,
            dias: ta.dias,
            fc: ta.fc,
            satu: ta.satu,
            co2: ta.co2,
            papSys: ta.papSys,
            papDias: ta.papDias,
            drug: "",
            comment: ""
          }
          ];
        });
      }


    }
  }

  function handleInt() {
    if (interval === 5) {
      setMin(10);
    }
    else setMin(5);
  }

  function addCut() {
    setIsCut(true);
  }

  function addEnd() {
    setIsEnd(true);
  }

  function addExtub() {

  }

  function change(newTa, index) {
    const newTendancesPat = tendancesPat;
    newTendancesPat.splice(index, 1);
    newTendancesPat.splice(index, 0, newTa);
    setTendancesPat(() => {
      return newTendancesPat
    }
    );

  }

  function addAnalgesique(newAnalgesique) {


    setAnalgesiqueRecu(prevValue => {
      return [...prevValue, newAnalgesique]
    })
  }

  return (
    <div>
      <div className="bordsup"></div>
      <Clock
        tendancesPat={tendancesPat}
        patientName={patientName}
        chosenName={restore}
      />

      <Patient
        anes={handleAnes}
        setPatientName={setPatientName}
        patientName={patientName}
      />

      <br />

      <hr className="ligne" />
      <InductionProtocol
        setCo2IsShow={setCo2IsShow}
        setExtubIsShow={setExtubIsShow}
        setCardiacParamIsShow={setCardiacParamIsShow}
        totalFluid={totalFluid}
        setTotalFluid={setTotalFluid}
      />

      <hr className="ligne" />

      <div className="tendances">

        {isRestored === false &&
          <StartMonitoring
            label="Induction"
            cardiacParamIsShow={cardiacParamIsShow}
            onAddParam={addParam}
          />
        }

        {isRestored &&
          <StartMonitoRestored
            label="Induction"
            heureInd={heureInd}
          />
        }

        {isInduced &&
          <StartMonitoring
            label="Incision"
            onAddParam={addCut}
          />
        }

        {isCut &&
          <ExtubationButton
            label="End of operation"
            onAddParam={addEnd}
          />
        }

        {isEnd && extubIsShow ?
          <ExtubationButton
            label="Extubation"
            onAddParam={addExtub}
          /> : null
        }

        {isInduced &&
          <Entetecol
            co2IsShow={co2IsShow}
          />
        }

        {isInduced &&
          tendancesPat.map((tendance, index) => (
            <div key={index}>
              <Grid container>
                <Grid item xs={12}>
                  <TaSlider
                    key={index}
                    id={index}
                    tendance={tendance}
                    changeHemo={change}
                    co2IsShow={co2IsShow}
                    cardiacParamIsShow={cardiacParamIsShow}
                    addAnalgesique={addAnalgesique}
                  />
                </Grid>
              </Grid>
            </div>
          ))


        }
        <br />
        <br />
        {isInduced &&
          <IconButton variant="contained" color="primary" size="small" onClick={() => addTend(tendancesPat[tendancesPat.length - 1])}>
            <AddBoxIcon style={{ color: "#00C387" }} fontSize="large" />
          </IconButton>}
        {isInduced &&
          <Button style={{ backgroundColor: "#00C387", color: "white" }} size="small" onClick={() => handleInt()}>{interval} min</Button>}

      </div>

      <hr className="ligne" />
      <div id="reveil" className="reveil">
        <br />
        <Recu
          analgesiqueRecu={analgesiqueRecu}
          setAnalgesiqueRecu={setAnalgesiqueRecu}
        />
        <br />
        <br />
        <Reveil
        patientName={patientName}
        />

        <OneDay/>
        <br />
        <Typography>Anesthesist: {anesName}</Typography>
        <br />
        <br />
        <Typography>Signature</Typography>

      </div>

    </div>
  );
}

export default App;
