import React from 'react';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ListDrugs from './Drugs';
import Comment from './Comments';
import Heure from './Heure';



function TaSlider({ id, co2IsShow, tendance, changeHemo, addAnalgesique }) {

    const [hemo, setHemo] = useState(tendance)
    const [isShown, setIsShown] = useState(false);


    function handleChange(event, newValue) {
        const newParam = hemo;

        newParam.dias = newValue[0];
        newParam.syst = newValue[1];
        changeHemo(newParam, id);
        setHemo(prevValue => {
            return {
                ...prevValue, dias: newValue[0], syst: newValue[1]
            }
        });

    }

    function handleFc(event, newValue) {
        const newParam = hemo;

        newParam.fc = newValue;
        changeHemo(newParam, id);
        setHemo(prevValue => {
            return {
                ...prevValue, fc: newValue
            }
        });

    }

    function handleClick(x) {

        if (hemo.satu + x < 101) {
            const newParam = hemo;

            newParam.satu = newParam.satu + x;
            changeHemo(newParam, id);
            setHemo(prevValue => {
                return {
                    ...prevValue, satu: newParam.satu
                }
            });
        }

    }

    function handleCo2(x) {
        const newParam = hemo;

        newParam.co2 = newParam.co2 + x;
        changeHemo(newParam, id);
        setHemo(prevValue => {
            return {
                ...prevValue, co2: newParam.co2
            }
        });


    }

    function addDrug(drug) {
        setIsShown(false);
        const newParam = hemo;
        newParam.drug = drug;
        changeHemo(newParam, id);
        setHemo(prevValue => {
            return {
                ...prevValue, drug: newParam.drug
            }
        })
    }

    function addComment(comment) {
        setIsShown(false);
        const newParam = hemo;
        newParam.comment = comment;
        changeHemo(newParam, id);
        setHemo(prevValue => {
            return {
                ...prevValue, comment: newParam.comment
            }
        })
    }



    const marks = [
        {
            value: 50,
            label: '50'
        },
        {
            value: 100,
            label: '100'
        },
        {
            value: 150,
            label: '150'
        },
    ];

    return (
        <div className='slider'>
            <Grid container >
                <Grid item sx={{ height: '30px' }} xs={1}>
                    <Heure
                        heure={hemo.heureMsec}
                    />
                </Grid>

                <Grid item sx={{ height: '30px' }} xs={7} >

                    <Slider
                        min={0}
                        max={230}
                        marks={marks}
                        defaultValue={[hemo.dias, hemo.syst]}
                        valueLabelDisplay='auto'
                        onChangeCommitted={handleChange}
                        sx={{
                            left: '-50px',
                            margin: 0,
                            '& .MuiSlider-rail': {
                                color: '#ffffff',
                                opacity: 0
                            },
                            '& .MuiSlider-thumb': {
                                height: 10,
                                width: 10,
                                color: '#039BFF',
                                // '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                //     boxShadow: 'inherit',
                                // },
                                '&:after': {
                                    height: 20,
                                    width: 20
                                },
                            },
                            '& .MuiSlider-valueLabel': {
                                backgroundColor: '#039BFF',
                                top: -5,
                                paddingRight: "3px",
                                paddingLeft: "3px",
                                paddingTop: "1px",
                                paddingBottom:"1px"
                            },
                            '& .MuiSlider-track': {
                                color: '#039BFF',
                                height: '1px'
                            },
                            '& .MuiSlider-mark': {
                                height: 8,
                                color: '#039BFF'
                            },
                            '& .MuiSlider-markLabel': {
                                fontSize: 8
                            }
                        }}
                    />

                    <Slider
                        min={0}
                        max={230}
                        defaultValue={hemo.fc}
                        valueLabelDisplay='auto'
                        onChangeCommitted={handleFc}
                        sx={{
                            position: "relative",
                            top: -39,
                            left: 170,
                            height: 4,
                            paddingTop: 0,
                            paddingBottom: 0,
                            '& .MuiSlider-rail': {
                                color: '#ffffff',
                                opacity: 0,
                            },
                            '& .MuiSlider-track': {
                                color: '#ffffff',
                                opacity: 0
                            },
                            '& .MuiSlider-thumb': {
                                height: 10,
                                width: 10,
                                backgroundColor: "#FE019A",
                                // '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                                //     boxShadow: 'inherit',
                                // },
                                '&:after': {
                                    height: 20,
                                    width: 20
                                },
                            },
                            '& .MuiSlider-valueLabel': {
                                backgroundColor: '#FE019A',
                                opacity: 0.8,
                                top: -5,
                                paddingRight: "3px",
                                paddingLeft: "3px",
                                paddingTop: "1px",
                                paddingBottom:"1px"
                            }
                        }}
                    />
                </Grid>




                <Grid item xs={1} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} >
                    <Typography variant="caption">{hemo.satu}</Typography>
                    {isShown && (
                        <React.Fragment>
                            <IconButton sx={{ padding: 0 }} style={{ color: "gray" }} onClick={() => handleClick(1)} >
                                <AddCircleOutlineIcon sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                            <IconButton sx={{ padding: 0 }} style={{ color: "gray" }} onClick={() => handleClick(-1)} >
                                <RemoveCircleOutlineIcon sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </React.Fragment>
                    )}
                </Grid>
                {co2IsShow && (
                    <Grid item xs={1} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} >
                        <Typography variant="caption">{hemo.co2}</Typography>
                        {isShown && (
                            <React.Fragment>
                                <IconButton sx={{ padding: 0 }} style={{ color: "gray" }} onClick={() => handleCo2(1)} >
                                    <AddCircleOutlineIcon sx={{ fontSize: '0.8rem' }} />
                                </IconButton>
                                <IconButton sx={{ padding: 0 }} style={{ color: "gray" }} onClick={() => handleCo2(-1)} >
                                    <RemoveCircleOutlineIcon sx={{ fontSize: '0.8rem' }} />
                                </IconButton>
                            </React.Fragment>
                        )}
                    </Grid>)
                }

                <Grid item xs={1}>
                    <Typography variant='caption'>{hemo.syst + "/" + hemo.dias}</Typography>
                </Grid>


                <Grid item xs={1}>
                    <Typography variant='caption'>{"\xa0\xa0" + hemo.fc}</Typography>
                </Grid>

            </Grid>

            <Grid container >

                <Grid item xs={6} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                    {isShown && (<ListDrugs
                        drug={addDrug}
                        hemoDrug={hemo.drug}
                        heure={hemo.heureMsec}
                        addAnalgesique={addAnalgesique}
                    />)}
                    <Typography sx={{ height: '30px' }} variant="caption">
                        {hemo.drug}
                    </Typography>
                </Grid>

                <Grid sx={{ height: '30px' }} item xs={6} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                    {isShown && (<Comment
                        comment={addComment}
                        hemoComment={hemo.comment}
                    />)}
                    <Typography sx={{lineHeight:0, height:'30px'}} variant="caption">
                        {hemo.comment}
                    </Typography>
                </Grid>

            </Grid>
        </div>
    )


}
export default TaSlider;