import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import { DialogContent } from '@mui/material';
import ReveilList from '../ReveilList';



function PopReveil(props) {
    
    const antalgique= props.rachiPonction
    const [PonctionChoisies, setChosenPonction] = useState(antalgique);

    function handleClick() {
        props.close();
    }

    function handleSend() {
        props.chosenPonction(PonctionChoisies);
        props.close();
    }

    function addPonction(punct, index) {
        PonctionChoisies.splice(index, 1, punct);
        setChosenPonction([...PonctionChoisies]);
    }

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth='md'
        >
            <DialogTitle>{props.titre}</DialogTitle>
            <DialogContent dividers>
                <div>
                    <FormControl >
                        <FormGroup >
                            
                            {PonctionChoisies.map((punct, index) => {

                                return (
                                    <ReveilList
                                        key={index}
                                        drug={punct}
                                        index={index}
                                        send={addPonction}
                                    />
                                )
                            })}

                        </FormGroup>
                    </FormControl>

                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClick}>
                    Cancel
                    </Button>
                <Button color="primary" onClick={handleSend}>
                    OK
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PopReveil;