import React from "react";
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import PopMonitoring from "./PopUpInduction/PopMonitoring";
import { monitoring, vcVentilation, centralCatheter } from './DataProtocol';
import PopPonction from './PopUpInduction/PopPonction';
import PopCatheter from './PopUpInduction/PopCatheter';
import Fluid from '../Fluid';

function VoieCentraleProtocol({ totalFluid, setTotalFluid }) {

    const [fluidIsClicked, setFluidIsClicked] = useState(false);
    const [genMonitoIsClicked, setGenMonitoIsClicked] = useState(false);
    const [genCathIsClicked, setGenCathIsClicked] = useState(false);
    const [generalVentilationIsClicked, setGeneralVentilationIsClicked] = useState(false);
    const [generalMonitoring, setGeneralMonito] = useState(monitoring);
    const [generalCatheter, setGeneralCatheter] = useState(centralCatheter);
    const [generalVentilation, setGeneralVentilation] = useState(vcVentilation);

    var protoVc = "Request for placement of a deep vein catheter for medical reason by a physician colleague is completed \n Checking the intervention site with ultrasound \n Absence of local or general contraindication for the placement of a catheter \n Check coagulation, medication use and allergy of the patient \n Hands were washed and disinfected \n Wearing mask, hair protection and sterile gloves \n SonoSite SII ultrasound machine used during the procedure with sterile cough and sterile gel \n Disinfection of the operation site by the nurse \n Local anesthesia with linisol 2%: 5ml \n Placement of the deep venous catheter in one attempt \n No arterial ponction \n No air ponction \n Checking the position of the catheter with the ultrasound \n Control position catheter by means of an X-ray machine "

    function handleFluid() {
        setFluidIsClicked(true);
    }

    function handleMonito() {
        setGenMonitoIsClicked(true);
    }

    function handleCath() {
        setGenCathIsClicked(true);
    }


    function handleVentilation() {
        setGeneralVentilationIsClicked(true);
    }

    function removeFluid() {
        const long = totalFluid.length
        if (long > 0) {
            setTotalFluid(totalFluid.slice(0, long - 1))
        }
    }

    function addMonitoring(chosenMonitoring) {
        setGeneralMonito(chosenMonitoring)
    }


    function addPonction(chosenPonction) {
        setGeneralVentilation(chosenPonction)
    }

    function addCatheter(chosenCath) {
        setGeneralCatheter(chosenCath)
    }

    return (
        <React.Fragment>
            <Grid>
                <Paper sx={{
                    marginBottom: "5px",
                    marginTop: "5px"
                }}>
                    <Typography
                        variant="caption">
                        Before the procedure starts, the nurse in charge checks the used material on: sterility, expiry date, closed packaging without defects.
                    </Typography>
                    <br />
                    <Typography variant="caption">
                        The material to be used is prepared sterile (deep vein catheter, connection pieces, compresses, sterile fields, suture materials, gloves, drapes, sterile cough and ultrasound gel).
                    </Typography>
                    <br />
                    <Typography variant="caption">
                        The intravenous line with a purged tubing is ready
                    </Typography>
                </Paper>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Paper>
                        <Typography display="inline" variant="h6">Monitoring </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleMonito} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopMonitoring
                            open={genMonitoIsClicked}
                            inductionMonitoring={generalMonitoring}
                            titre="General anesthesia"
                            chosenMonitoring={addMonitoring}
                            close={() => setGenMonitoIsClicked(false)}
                        />
                        {generalMonitoring.map((monito, index) => {
                            return monito.selected && (
                                <Typography key={index} variant="body2" >{monito.name} </Typography>
                            )
                        })}

                    </Paper>
                    <Paper>
                        <Typography display="inline" variant="h6">Catheters </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleCath} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopCatheter
                            open={genCathIsClicked}
                            generalCatheter={generalCatheter}
                            chosenCatheter={addCatheter}
                            close={() => setGenCathIsClicked(false)}
                        />
                        {generalCatheter.map((cath, index) => {
                            return cath.selected && (
                                <Typography key={index} variant="body2" >{cath.name}  {cath.dose} </Typography>
                            )
                        })}

                    </Paper>
                </Grid>







                <Grid item xs={3}>
                    <Paper>
                        <Typography display="inline" variant="h6">Varied</Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleVentilation} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopPonction
                            open={generalVentilationIsClicked}
                            rachiPonction={generalVentilation}
                            titre="General anesthesia"
                            chosenPonction={addPonction}
                            close={() => setGeneralVentilationIsClicked(false)}

                        />
                        {generalVentilation.map((punct, index) => {
                            return punct.selected && (
                                <Typography key={index} variant="body2" >{punct.name} {punct.dose}</Typography>
                            )
                        }
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={5}>
                    <Paper>
                        <Typography display="inline" variant="h6">Ponction </Typography>

                        <Divider />
                        <TextField 
                        multiline 
                        variant="standard" 
                        sx={{width:"100%" }} 
                        inputProps = {{style:{fontSize:12}}}
                        defaultValue= {protoVc}/>
                    </Paper>
                </Grid>

                <Grid item xs={2}>
                    <Paper>
                        <Typography variant="h6">Fluids
                            <IconButton onClick={handleFluid} style={{ color: "#FE019A" }} size="small" >
                                <AddCircleIcon />
                            </IconButton>
                            <IconButton onClick={removeFluid} style={{ color: "#FE019A" }} size="small" >
                                <RemoveCircleIcon />
                            </IconButton>
                        </Typography>
                        <Divider />

                        {fluidIsClicked &&
                            <Fluid
                                totalFluid={totalFluid}
                                setTotalFluid={setTotalFluid}
                                close={() => setFluidIsClicked(false)}
                            />
                        }

                        {totalFluid.map((fluid, index) => {
                            return (
                                <Typography key={index} variant="body2" >{fluid}</Typography>
                            )
                        })}
                    </Paper>
                </Grid>

            </Grid>
            <br />
            <TextField variant="standard" style={{ width: "75%" }} multiline label="Comments" />
        </React.Fragment>
    )
};


export default VoieCentraleProtocol;
