import React from 'react';
import { Grid, Typography } from '@mui/material';


function Entetecol(props) {
    
    return (
        <div className='entete' >
            <Grid container>
                <Grid item xs={1}>
                    <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b>Hour</b>
                    </Typography>

                </Grid>
                <Grid item xs={7}>

                </Grid>
                <Grid item xs={1}>
                    <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b> SpO2<br/> (%)</b>
                    </Typography>
                </Grid>
                {props.co2IsShow &&
                <Grid item xs={1}>
                    <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b> CO2<br/> (mmHg)</b>
                    </Typography>
                </Grid>
                }
                
                <Grid item xs={1}>
                    <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b> BP<br/> (mmHg)</b>
                    </Typography>
                </Grid>
                
                <Grid item xs={1}>
                    <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b> HR<br/> (min)</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b>Drugs</b>
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                <Typography sx={{fontSize:10, textAlign:'left'}} >
                        <b>Comments</b>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Entetecol;