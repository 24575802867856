import React from "react";
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import PopMonitoring from "./PopUpInduction/PopMonitoring";
import { monitoring, regionalDrugs, regionalPonction } from './DataProtocol';
import PopInduction from './PopUpInduction/PopInduction';
import PopPonction from './PopUpInduction/PopPonction';
import Fluid from '../Fluid';


function RegionalProtocol({ totalFluid, setTotalFluid }) {

    const [fluidIsClicked, setFluidIsClicked] = useState(false);
    const [monitoIsClicked, setMonitoIsClicked] = useState(false);
    const [rachiDrugsIsClicked, setRachiDrugsIsClicked] = useState(false);
    const [rachiPonctionIsClicked, setRachiPonctionIsClicked] = useState(false);
    const [inductionMonitoring, setRachiMonito] = useState(monitoring);
    const [inductionRachiDrugs, setRachiDrugs] = useState(regionalDrugs);
    const [inductionRachiPonction, setRachiPonction] = useState(regionalPonction);



    function handleFluid() {
        setFluidIsClicked(true);
    }

    function handleMonito() {
        setMonitoIsClicked(true);
    }

    function handleInduction() {
        setRachiDrugsIsClicked(true);
    }

    function handlePonction() {
        setRachiPonctionIsClicked(true);
    }

    function removeFluid() {
        const long = totalFluid.length
        if (long > 0) {
            setTotalFluid(totalFluid.slice(0, long - 1))
        }
    }

    function addMonitoring(chosenMonitoring) {
        setRachiMonito(chosenMonitoring)
    }

    function addDrugs(chosenDrugs) {
        setRachiDrugs(chosenDrugs)
    }

    function addPonction(chosenPonction) {
        setRachiPonction(chosenPonction)
    }
    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Paper>
                        <Typography display="inline" variant="h6">Monitoring </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleMonito} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopMonitoring
                            open={monitoIsClicked}
                            inductionMonitoring={inductionMonitoring}
                            chosenMonitoring={addMonitoring}
                            close={() => setMonitoIsClicked(false)}
                        />
                        {inductionMonitoring.map((monito, index) => {
                            return monito.selected && (
                                <Typography key={index} variant="body2" >{monito.name} </Typography>
                            )
                        })}

                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper>
                        <Typography display="inline" variant="h6">Induction </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleInduction} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopInduction
                            open={rachiDrugsIsClicked}
                            rachiDrugs={inductionRachiDrugs}
                            chosenDrugs={addDrugs}
                            close={() => setRachiDrugsIsClicked(false)}

                        />
                        {inductionRachiDrugs.map((drug, index) => {
                            return drug.selected && (
                                <Typography key={index} variant="body2" >{drug.name}  {drug.dose}</Typography>
                            )
                        }
                        )}
                    </Paper>
                </Grid>





                <Grid item xs={4}>
                    <Paper>
                        <Typography display="inline" variant="h6">Ponction </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handlePonction} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopPonction
                            open={rachiPonctionIsClicked}
                            rachiPonction={inductionRachiPonction}
                            titre="Regional anesthesia"
                            chosenPonction={addPonction}
                            close={() => setRachiPonctionIsClicked(false)}

                        />
                        {inductionRachiPonction.map((punct, index) => {
                            return punct.selected && (
                                <Typography key={index} variant="body2" >{punct.name} {punct.dose}</Typography>
                            )
                        }
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={2}>
                    <Paper>
                    <Typography variant="h6">Fluids
                        <IconButton onClick={handleFluid} style={{ color: "#FE019A" }} size="small" >
                                <AddCircleIcon />
                            </IconButton>
                            <IconButton onClick={removeFluid} style={{ color: "#FE019A" }} size="small" >
                                <RemoveCircleIcon />
                            </IconButton>
                        </Typography>
                        <Divider />

                        {fluidIsClicked &&
                            <Fluid
                                totalFluid={totalFluid}
                                setTotalFluid={setTotalFluid}
                                close={() => setFluidIsClicked(false)}
                            />
                        }

                        {totalFluid.map((fluid, index) => {
                            return (
                                <Typography key={index} variant="body2" >{fluid}</Typography>
                            )
                        })}
                    </Paper>
                </Grid>

            </Grid>
            <br />
            <TextField variant="standard" style={{ width: "75%" }} multiline label="Comments" />
        </React.Fragment>
    )
};


export default RegionalProtocol;
