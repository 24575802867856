import React from "react";
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import PopMonitoring from "./PopUpInduction/PopMonitoring";
import { monitoring, laryngealDrugs, laryngealVentilation, catheter } from './DataProtocol';
import PopInduction from './PopUpInduction/PopInduction';
import PopPonction from './PopUpInduction/PopPonction';
import PopCatheter from './PopUpInduction/PopCatheter';
import Fluid from '../Fluid';

function LaryngealProtocol({ totalFluid, setTotalFluid }) {

    const [fluidIsClicked, setFluidIsClicked] = useState(false);
    const [genMonitoIsClicked, setGenMonitoIsClicked] = useState(false);
    const [genCathIsClicked, setGenCathIsClicked] = useState(false);
    const [generalDrugsIsClicked, setGeneralDrugsIsClicked] = useState(false);
    const [generalVentilationIsClicked, setGeneralVentilationIsClicked] = useState(false);
    const [generalMonitoring, setGeneralMonito] = useState(monitoring);
    const [generalCatheter, setGeneralCatheter] = useState(catheter);
    const [generalDrugs, setGeneralDrugs] = useState(laryngealDrugs);
    const [generalVentilation, setGeneralVentilation] = useState(laryngealVentilation);



    function handleFluid() {
        setFluidIsClicked(true);
    }

    function handleMonito() {
        setGenMonitoIsClicked(true);
    }

    function handleCath() {
        setGenCathIsClicked(true);
    }

    function handleInduction() {
        setGeneralDrugsIsClicked(true);
    }

    function handleVentilation() {
        setGeneralVentilationIsClicked(true);
    }

    function removeFluid() {
        const long = totalFluid.length
        if (long > 0) {
            setTotalFluid(totalFluid.slice(0, long - 1))
        }
    }

    function addMonitoring(chosenMonitoring) {
        setGeneralMonito(chosenMonitoring)
    }

    function addDrugs(chosenDrugs) {
        setGeneralDrugs(chosenDrugs)
    }

    function addPonction(chosenPonction) {
        setGeneralVentilation(chosenPonction)
    }

    function addCatheter(chosenCath) {
        setGeneralCatheter(chosenCath)
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Paper>
                        <Typography display="inline" variant="h6">Monitoring </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleMonito} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopMonitoring
                            open={genMonitoIsClicked}
                            inductionMonitoring={generalMonitoring}
                            titre="General anesthesia"
                            chosenMonitoring={addMonitoring}
                            close={() => setGenMonitoIsClicked(false)}
                        />
                        {generalMonitoring.map((monito, index) => {
                            return monito.selected && (
                                <Typography key={index} variant="body2" >{monito.name} </Typography>
                            )
                        })}

                    </Paper>
                    <Paper>
                        <Typography display="inline" variant="h6">Catheters </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleCath} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopCatheter
                            open={genCathIsClicked}
                            generalCatheter={generalCatheter}
                            chosenCatheter={addCatheter}
                            close={() => setGenCathIsClicked(false)}
                        />
                        {generalCatheter.map((cath, index) => {
                            return cath.selected && (
                                <Typography key={index} variant="body2" >{cath.name}  {cath.dose} </Typography>
                            )
                        })}

                    </Paper>
                </Grid>

                <Grid item xs={3}>
                    <Paper>
                        <Typography display="inline" variant="h6">Induction </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleInduction} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopInduction
                            open={generalDrugsIsClicked}
                            rachiDrugs={generalDrugs}
                            titre="General anesthesia"
                            chosenDrugs={addDrugs}
                            close={() => setGeneralDrugsIsClicked(false)}

                        />
                        {generalDrugs.map((drug, index) => {
                            return drug.selected && (
                                <Typography key={index} variant="body2" >{drug.name}  {drug.dose}</Typography>
                            )
                        }
                        )}
                    </Paper>
                </Grid>





                <Grid item xs={4}>
                    <Paper>
                        <Typography display="inline" variant="h6">Ventilation </Typography>
                        <IconButton style={{ color: "#FE019A" }} onClick={handleVentilation} size="small" >
                            <EditIcon />
                        </IconButton>
                        <Divider />
                        <PopPonction
                            open={generalVentilationIsClicked}
                            rachiPonction={generalVentilation}
                            titre="General anesthesia"
                            chosenPonction={addPonction}
                            close={() => setGeneralVentilationIsClicked(false)}

                        />
                        {generalVentilation.map((punct, index) => {
                            return punct.selected && (
                                <Typography key={index} variant="body2" >{punct.name} {punct.dose}</Typography>
                            )
                        }
                        )}
                    </Paper>
                </Grid>

                <Grid item xs={2}>
                    <Paper>
                    <Typography variant="h6">Fluids
                        <IconButton onClick={handleFluid} style={{ color: "#FE019A" }} size="small" >
                                <AddCircleIcon />
                            </IconButton>
                            <IconButton onClick={removeFluid} style={{ color: "#FE019A" }} size="small" >
                                <RemoveCircleIcon />
                            </IconButton>
                        </Typography>
                        <Divider />

                        {fluidIsClicked &&
                            <Fluid
                                totalFluid={totalFluid}
                                setTotalFluid={setTotalFluid}
                                close={() => setFluidIsClicked(false)}
                            />
                        }

                        {totalFluid.map((fluid, index) => {
                            return (
                                <Typography key={index} variant="body2" >{fluid}</Typography>
                            )
                        })}
                    </Paper>
                </Grid>

            </Grid>
            <br />
            <TextField variant="standard" style={{ width: "75%" }} multiline label="Comments" />
        </React.Fragment>
    )
};


export default LaryngealProtocol;
