import React, { Fragment } from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';



function Restore({setIsClicked, isClicked, stored, chosenName}) {
    const [nameChosen, setNameChosen] = useState("")
    const [index, setIndex] = useState()
    const [patStored , setPatientStored] = useState(stored)
 
    function handleName(name,index) {
        setNameChosen(name)
        setIndex(index)
    }
    
    function handleClick() {
        setIsClicked(!isClicked);
    }

    function handleSend() {
        if (nameChosen=== "") {
            setIsClicked(!isClicked); 
        } else {
            chosenName(nameChosen);
            setIsClicked(!isClicked);
        }
        
    }

    function handleDelete() {
        localStorage.removeItem(nameChosen)
        console.log(index)
        const newPatStore = patStored
        newPatStore.splice(index, 1)
        setPatientStored(newPatStore)
        setNameChosen("")
    } 

    return (
        <Dialog open = {isClicked}>
        <DialogTitle>Selected backup: {nameChosen}</DialogTitle>
        <DialogContent dividers>
        <Grid style={{ padding: '10px' }} container >
                        {
                            patStored.map((key, index) => {
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={12}>

                                            <List>
                                                <ListItem button style={{ width: "90%" }} name={key} onClick={() => handleName(key, index)}>
                                                    <ListItemText primary={key} />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Fragment>
                                )
                            })}
                    </Grid>
        </DialogContent>
        
        <DialogActions>
                    <Button onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button color="secondary" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button color="primary" onClick={handleSend}>
                        OK
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default Restore;