import React, { useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { DialogContent} from '@mui/material';
import GeneralList from '../GeneralList';



function PopInduction (props) {
    
    const [chosenDrugs, setChosenDrugs] = useState(props.rachiDrugs);
    
    function handleClick() {
        props.close();
            }

    function handleSend() {
        props.chosenDrugs(chosenDrugs);
        
        props.close();

    }

    function addDrugs(drug, index) {

        const newChosenDrugs = chosenDrugs
        newChosenDrugs.splice(index, 1, drug)
        setChosenDrugs(newChosenDrugs)
    }

    return (
    <Dialog
            open={props.open}
            maxWidth="lg"
        >
            <DialogTitle>{props.titre}</DialogTitle>
            <DialogContent dividers>
                <div>
                    <FormControl >
                        <FormGroup >
                            <FormLabel>Drugs</FormLabel>
                            {chosenDrugs.map((drug, index) => {

                                return (
                                    <GeneralList
                                        key={index}
                                        drug={drug}
                                        index={index}
                                        send={addDrugs}
                                    />
                                )
                            })}

                        </FormGroup>
                    </FormControl>
                
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={handleClick}>
                    Cancel
                    </Button>
                <Button color="primary" onClick={handleSend}>
                    OK
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PopInduction;