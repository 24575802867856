import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';


function StartMonitoRestored(props) {
    
    return (
        <React.Fragment>
            <ButtonGroup size="small" variant="outlined"  >
                <Button  disabled style={{ backgroundColor: "gray", color: "white" }} startIcon={<AirlineSeatFlatIcon />} variant="contained" color="primary" size="small" >{props.label} {props.heureInd}</Button>
            </ButtonGroup>
        </React.Fragment>
    )
};

export default StartMonitoRestored;

